<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'marketplace-adv': true,
    'label-discount': labeldiscount === 'yes',
  }">
    <div class="marketplace-ad-container" v-bind:class="{'vip-ad': isVip}">
      <div class="d-flex">
        <div class=" me-auto">
          <div class="label-container">
            <LabelNew class="label-new" v-if="labelnew" :language="'english'"/>
            <span class="badge bg-warning" v-if="isVip">VIP</span>
            <LabelDiscount class="label-discount mt-1" v-if="labeldiscount && discountedamount && discountedamount > 0" :amount="Math.round(((discountedamount - regularamount) / regularamount) * -100)"/>
          </div>
        </div>
        <div class="fav-icon">
          <AddToFavourites :product="{
            id: id,
            image: image,
            labeldiscount: labeldiscount,
            title: title,
            category: category,
            labelnew: labelnew,
            regularamount: regularamount,
            discountedamount: discountedamount,
          }"/>
        </div>
      </div>
      <router-link :to="{name: 'SingleProductPage', params: {id: id}}" class="marketplace-ad-title">
        <div class="thumb-image" v-bind:style="{'background-image': 'url(/storage/' + image + ')'}">
        </div>
      </router-link>
      <div class="marketplace-ad-text mt-4">
        <router-link :to="{name: 'SingleProductPage', params: {id: id}}" class="marketplace-ad-title">
          {{ title }}
        </router-link>
        <div class="marketplace-category">
          {{ category }}
        </div>
      </div>
      <div class="marketplace-price-tag mt-auto">
        <div class="old-price" v-if="discountedamount && discountedamount > 0">
          <div class="price-amount">
            {{ Number.isInteger(regularamount) ? regularamount : regularamount.toFixed(2) }} {{ $t('currency') }}
          </div>
        </div>
        <div class="discounted-price" v-if="discountedamount && discountedamount > 0">
          <div class="price-amount">
            {{Number.isInteger(discountedamount) ? discountedamount : discountedamount.toFixed(2) }} {{ $t('currency') }}
          </div>
        </div>
        <div class="regular-price pt-3" v-else>
            <div class="price-amount">
                {{ Number.isInteger(regularamount) ? regularamount : regularamount.toFixed(2) }} {{ $t('currency') }}
            </div>
        </div>
      </div>

    </div>
  </div>
</template>
